export type DepartmentCategory = '内科' | '外科' | 'その他';

type Department = {
  id: number;
  name: string;
  articlesCount: number;
  displayOrder: number;
  category: DepartmentCategory;
};

const INTERNAL = '内科';
const SURGERY = '外科';
const OTHER = 'その他';

/**
 * 診療科をカテゴリーに分類する
 * @param department
 * @returns
 */
export function departmentCategory(department: Department): DepartmentCategory {
  let category = OTHER;
  if (department.displayOrder < 200) {
    category = INTERNAL;
  } else if (department.displayOrder < 300) {
    category = OTHER;
  } else if (department.displayOrder < 400) {
    category = SURGERY;
  }
  return category as DepartmentCategory;
}

/**
 * 診療科をカテゴリーごとにグループ化する
 * @param departments
 * @returns
 */
export function categorizeDepartments(
  departments: Department[],
): Record<string, Department[]> {
  let categorizedDepartment: Record<string, Department[]> = {
    [INTERNAL]: [],
    [SURGERY]: [],
    [OTHER]: [],
  };
  [INTERNAL, SURGERY, OTHER].forEach(
    (category) =>
      (categorizedDepartment[category] = departments.filter(
        (department) => department.category === category,
      )),
  );
  return categorizedDepartment;
}
