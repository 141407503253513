import { useCallback } from 'react';
import monitoring from '../common/libs/monitoring';
import { useAppContext } from '../contexts/app';
import {
  CSRF_TOKEN_HEADER_NAME,
  CSRF_TOKEN_VERIFY_TARGET_METHODS,
} from '../server/middlewares/csrf';

export default function useApiClient() {
  const { csrfToken } = useAppContext();

  const fetchWrapper = async (input: RequestInfo, init?: RequestInit) => {
    let options;
    if (
      init?.method &&
      CSRF_TOKEN_VERIFY_TARGET_METHODS.includes(init.method.toUpperCase())
    ) {
      const additionalHeaders: any = {};
      additionalHeaders[CSRF_TOKEN_HEADER_NAME] = csrfToken;
      options = {
        ...init,
        headers: {
          ...init.headers,
          ...additionalHeaders,
        },
      };
    } else {
      options = init;
    }

    const res = await fetch(input, options);
    if (500 <= res.status && res.status <= 599) {
      const notifyMessage = await res
        .json()
        .then((data) => {
          return JSON.stringify(data?.error);
        })
        .catch(() => {
          return res.statusText;
        });
      monitoring.notify({
        name: `API ERROR DETECTED (${res.status})`,
        message: notifyMessage,
      });
    }

    return res;
  };

  const apiClient = useCallback(fetchWrapper, [csrfToken]);

  return { apiClient };
}
