import React, { useCallback } from 'react';
import useApiClient from '../hooks/useApiClient';
import monitoring from '../common/libs/monitoring';
import { useAppContext } from '../contexts/app';

export type Announce = {
  id: number;
  type?: string;
  message: string;
  linkUrl?: string | null;
  imgUrl?: string | null;
  collapseKey?: string | null;
  priority?: number | null;
  isNew: boolean;
  createDate: string; // ISO8601
  updateDate: string; // ISO8601
};

export type AnnounceContext = {
  hasUnread: boolean;
  announces: Announce[];
};

/**
 * お知らせに関するHooks
 */
export default function useAnnounce() {
  const { apiClient } = useApiClient();
  const { announces, setAnnounces } = useAppContext();
  const { userAnnounces, setUserAnnounces } = useAppContext();
  const { hasUnread, setHasUnread } = useAppContext();

  const fetchAnnounces = useCallback(async () => {
    try {
      const res = await apiClient('/api/announces', {
        method: 'GET',
      });
      if (res.ok) {
        const data = await res.json();
        setHasUnread(data.hasUnread);
        setAnnounces(data.announces);
        setUserAnnounces(data.userAnnounces);
      } else {
        throw new Error();
      }
    } catch (e) {
      monitoring.notify({
        name: `API ERROR DETECTED ${e}`,
        message: 'お知らせの取得に失敗しました。',
      });
    }
  }, [apiClient, setAnnounces, setHasUnread, setUserAnnounces]);

  const readAnnounces = useCallback(async () => {
    try {
      const res = await apiClient('/api/announces/read?type=all', {
        method: 'POST',
      });
      if (!res.ok) {
        throw new Error();
      }
    } catch (e) {
      monitoring.notify({
        name: `API ERROR DETECTED ${e}`,
        message: 'お知らせの読み込み通知（all）に失敗しました。',
      });
    }
  }, [apiClient]);

  const readUserAnnounces = useCallback(async () => {
    try {
      const res = await apiClient('/api/announces/read?type=user', {
        method: 'POST',
      });
      if (!res.ok) {
        throw new Error();
      }
    } catch (e) {
      monitoring.notify({
        name: `API ERROR DETECTED ${e}`,
        message: 'お知らせの読み込み通知（user）に失敗しました。',
      });
    }
  }, [apiClient]);

  return {
    announces,
    fetchAnnounces,
    readAnnounces,
    userAnnounces,
    readUserAnnounces,
    hasUnread,
    setHasUnread,
  };
}
