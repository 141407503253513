import { Icon, IconProps } from '@chakra-ui/react';
import {
  BadgeSeriesEnum,
  PostSlideBadgeTypeEnum,
  CommentBadgeTypeEnum,
} from '../common/libs/badgeType';

type BadgeGrade = number; // 0~11

function getVariant(grade: BadgeGrade, achieved: boolean) {
  const fillColor = (() => {
    if (!achieved) {
      return '#E6E6E6';
    }

    if (grade >= 8 && grade <= 11) {
      return '#FFC90B';
    } else if (grade >= 4 && grade <= 7) {
      return '#9B9B9B';
    } else if (grade >= 0 && grade <= 3) {
      return '#986C31';
    } else {
      return '#E6E6E6';
    }
  })();

  const hasStars = [3, 7, 11].includes(grade);
  const hasCrown = hasStars || [2, 6, 10].includes(grade);
  const hasCircle = hasCrown || [1, 5, 9].includes(grade);

  return {
    fillColor,
    hasStars,
    hasCrown,
    hasCircle,
  };
}

function PostSlideBadgeIcon({
  grade,
  achieved,
  ...props
}: IconProps & { grade: BadgeGrade; achieved: boolean }) {
  const { fillColor, hasCrown, hasStars, hasCircle } = getVariant(
    grade,
    achieved,
  );
  return (
    <Icon
      width="60px"
      height="53px"
      viewBox="0 0 60 53"
      fill={fillColor}
      {...props}
    >
      <circle cx="29" cy="32" r="21" />
      {hasCircle && <circle cx="29" cy="32" r="19.0909" fill="white" />}
      <circle cx="28.9999" cy="32" r="17.1818" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 25C21.7348 25 21.4804 25.1054 21.2929 25.2929C21.1054 25.4804 21 25.7348 21 26C21 26.2652 21.1054 26.5196 21.2929 26.7071C21.4804 26.8946 21.7348 27 22 27V35C22 35.5304 22.2107 36.0391 22.5858 36.4142C22.9609 36.7893 23.4696 37 24 37H26.586L25.293 38.293C25.1975 38.3852 25.1213 38.4956 25.0689 38.6176C25.0165 38.7396 24.9889 38.8708 24.9877 39.0036C24.9866 39.1364 25.0119 39.2681 25.0622 39.391C25.1125 39.5139 25.1867 39.6255 25.2806 39.7194C25.3745 39.8133 25.4861 39.8875 25.609 39.9378C25.7319 39.9881 25.8636 40.0134 25.9964 40.0123C26.1292 40.0111 26.2604 39.9835 26.3824 39.9311C26.5044 39.8787 26.6148 39.8025 26.707 39.707L29 37.414L31.293 39.707C31.4816 39.8892 31.7342 39.99 31.9964 39.9877C32.2586 39.9854 32.5094 39.8802 32.6948 39.6948C32.8802 39.5094 32.9854 39.2586 32.9877 38.9964C32.99 38.7342 32.8892 38.4816 32.707 38.293L31.414 37H34C34.5304 37 35.0391 36.7893 35.4142 36.4142C35.7893 36.0391 36 35.5304 36 35V27C36.2652 27 36.5196 26.8946 36.7071 26.7071C36.8946 26.5196 37 26.2652 37 26C37 25.7348 36.8946 25.4804 36.7071 25.2929C36.5196 25.1054 36.2652 25 36 25H22ZM33.707 29.707C33.8892 29.5184 33.99 29.2658 33.9877 29.0036C33.9854 28.7414 33.8802 28.4906 33.6948 28.3052C33.5094 28.1198 33.2586 28.0146 32.9964 28.0123C32.7342 28.01 32.4816 28.1108 32.293 28.293L29 31.586L27.707 30.293C27.5195 30.1055 27.2652 30.0002 27 30.0002C26.7348 30.0002 26.4805 30.1055 26.293 30.293L24.293 32.293C24.1975 32.3852 24.1213 32.4956 24.0689 32.6176C24.0165 32.7396 23.9889 32.8708 23.9877 33.0036C23.9866 33.1364 24.0119 33.2681 24.0622 33.391C24.1125 33.5139 24.1867 33.6255 24.2806 33.7194C24.3745 33.8133 24.4861 33.8875 24.609 33.9378C24.7319 33.9881 24.8636 34.0134 24.9964 34.0123C25.1292 34.0111 25.2604 33.9835 25.3824 33.9311C25.5044 33.8787 25.6148 33.8025 25.707 33.707L27 32.414L28.293 33.707C28.4805 33.8945 28.7348 33.9998 29 33.9998C29.2652 33.9998 29.5195 33.8945 29.707 33.707L33.707 29.707Z"
        fill="white"
      />
      {hasCrown && (
        <path d="M33.3421 11.5927H24.5125C24.3872 11.5927 24.2793 11.5046 24.2544 11.3818L23.0053 5.2603C22.9844 5.15782 23.026 5.05271 23.1115 4.99238C23.1969 4.93192 23.3099 4.92797 23.3995 4.98197L26.7695 7.01321L28.6914 3.14621C28.7355 3.05743 28.8259 3.00079 28.9251 3C29.0184 2.99961 29.1154 3.05414 29.1611 3.14226L31.1468 6.97527L34.4553 4.98211C34.545 4.9281 34.6577 4.93218 34.7432 4.99251C34.8287 5.05284 34.8703 5.15795 34.8495 5.26043L33.6004 11.3819C33.5751 11.5046 33.4672 11.5927 33.3421 11.5927Z" />
      )}
      {hasStars && (
        <>
          <path d="M5.5 13L6.9855 17.0145L11 18.5L6.9855 19.9855L5.5 24L4.0145 19.9855L0 18.5L4.0145 17.0145L5.5 13Z" />
          <path d="M7.5 40L8.9855 44.0145L13 45.5L8.9855 46.9855L7.5 51L6.0145 46.9855L2 45.5L6.0145 44.0145L7.5 40Z" />
          <path d="M54.7857 31L56.0783 34.4931L59.5714 35.7857L56.0783 37.0783L54.7857 40.5714L53.4931 37.0783L50 35.7857L53.4931 34.4931L54.7857 31Z" />
        </>
      )}
    </Icon>
  );
}

function CommentBadgeIcon({
  grade,
  achieved,
  ...props
}: IconProps & { grade: BadgeGrade; achieved: boolean }) {
  const { fillColor, hasCrown, hasStars, hasCircle } = getVariant(
    grade,
    achieved,
  );
  return (
    <Icon
      width="60px"
      height="53px"
      viewBox="0 0 60 53"
      fill={fillColor}
      {...props}
    >
      <circle cx="29" cy="32" r="21" />
      {hasCircle && <circle cx="29" cy="32" r="19.0909" fill="white" />}
      <circle cx="28.9999" cy="32" r="17.1818" />
      <path
        d="M33.4444 29.7778C33.4444 26.7083 30.2118 24.2222 26.2222 24.2222C22.2326 24.2222 19 26.7083 19 29.7778C19 30.9688 19.4896 32.066 20.3194 32.9722C19.8542 34.0208 19.0868 34.8542 19.0764 34.8646C19 34.9445 18.9792 35.0625 19.0243 35.1667C19.0694 35.2708 19.1667 35.3333 19.2778 35.3333C20.5486 35.3333 21.6007 34.9063 22.3576 34.4653C23.4757 35.0104 24.7986 35.3333 26.2222 35.3333C30.2118 35.3333 33.4444 32.8472 33.4444 29.7778ZM37.6806 37.4167C38.5104 36.5139 39 35.4132 39 34.2222C39 31.8993 37.1424 29.9097 34.5104 29.0799C34.5417 29.309 34.5556 29.5417 34.5556 29.7778C34.5556 33.4549 30.816 36.4445 26.2222 36.4445C25.8472 36.4445 25.4826 36.4167 25.1215 36.3785C26.2153 38.375 28.7847 39.7778 31.7778 39.7778C33.2014 39.7778 34.5243 39.4583 35.6424 38.9097C36.3993 39.3507 37.4514 39.7778 38.7222 39.7778C38.8333 39.7778 38.934 39.7118 38.9757 39.6111C39.0208 39.5104 39 39.3924 38.9236 39.309C38.9132 39.2986 38.1458 38.4688 37.6806 37.4167Z"
        fill="white"
      />
      {hasCrown && (
        <path d="M33.3421 11.5927H24.5125C24.3872 11.5927 24.2793 11.5046 24.2544 11.3818L23.0053 5.2603C22.9844 5.15782 23.026 5.05271 23.1115 4.99238C23.1969 4.93192 23.3099 4.92797 23.3995 4.98197L26.7695 7.01321L28.6914 3.14621C28.7355 3.05743 28.8259 3.00079 28.9251 3C29.0184 2.99961 29.1154 3.05414 29.1611 3.14226L31.1468 6.97527L34.4553 4.98211C34.545 4.9281 34.6577 4.93218 34.7432 4.99251C34.8287 5.05284 34.8703 5.15795 34.8495 5.26043L33.6004 11.3819C33.5751 11.5046 33.4672 11.5927 33.3421 11.5927Z" />
      )}
      {hasStars && (
        <>
          <path d="M5.5 13L6.9855 17.0145L11 18.5L6.9855 19.9855L5.5 24L4.0145 19.9855L0 18.5L4.0145 17.0145L5.5 13Z" />
          <path d="M7.5 40L8.9855 44.0145L13 45.5L8.9855 46.9855L7.5 51L6.0145 46.9855L2 45.5L6.0145 44.0145L7.5 40Z" />
          <path d="M54.7857 31L56.0783 34.4931L59.5714 35.7857L56.0783 37.0783L54.7857 40.5714L53.4931 37.0783L50 35.7857L53.4931 34.4931L54.7857 31Z" />
        </>
      )}
    </Icon>
  );
}

export default function BadgeIcon({
  seriesId,
  typeId,
  grade,
  achieved,
  width = '60px',
  height = '53px',
}: {
  seriesId: BadgeSeriesEnum;
  typeId: PostSlideBadgeTypeEnum | CommentBadgeTypeEnum;
  grade: BadgeGrade;
  achieved: boolean;
  width?: number | string | undefined;
  height?: number | string | undefined;
}) {
  if (seriesId === BadgeSeriesEnum.PostSlide) {
    if (typeId === PostSlideBadgeTypeEnum.PostSlide) {
      return (
        <PostSlideBadgeIcon
          grade={grade}
          achieved={achieved}
          width={width}
          height={height}
        />
      );
    }
  } else if (seriesId === BadgeSeriesEnum.Comment) {
    if (typeId === CommentBadgeTypeEnum.PostComment) {
      return (
        <CommentBadgeIcon
          grade={grade}
          achieved={achieved}
          width={width}
          height={height}
        />
      );
    }
  }

  return <Icon />;
}
