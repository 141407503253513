import { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useBreakpointValue,
  Flex,
  Text,
  Link,
} from '@chakra-ui/react';
import { useAppContext } from '../contexts/app';
import useApiClient from '../hooks/useApiClient';
import { categorizeDepartments } from '../common/libs/department';
import monitoring from '../common/libs/monitoring';

type Department = {
  id: number;
  name: string;
  displayOrder: number;
  articlesCount: number;
};

type DepartmentByCategory = {
  [key: string]: Department[];
};

export default function DepartmentModal() {
  const modalSize = '570px';
  const { departmentsPopoverDisclosure } = useAppContext();
  const { apiClient } = useApiClient();
  const [departments, setDepartments] = useState<DepartmentByCategory>({});
  const [isClicked, setIsClicked] = useState(false);

  const fetchDepartments = useCallback(async () => {
    try {
      const res = await apiClient('/api/departments?withArticleCount=true', {
        method: 'GET',
      });
      if (res.ok) {
        const data = await res.json();
        setDepartments(categorizeDepartments(data.departments));
      } else {
        throw new Error();
      }
    } catch (e) {
      monitoring.notify({
        name: `API ERROR DETECTED ${e}`,
        message: '診療科の取得に失敗しました。',
      });
    }
  }, [apiClient]);

  useEffect(() => {
    (async () => {
      await fetchDepartments();
    })();
  }, [fetchDepartments]);

  return (
    <Modal
      size={modalSize}
      onClose={departmentsPopoverDisclosure.onClose}
      isOpen={departmentsPopoverDisclosure.isOpen}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={0} position="relative">
          <Flex
            direction="column"
            align="center"
            justify="center"
            bg="white"
            left={'240px'}
            top="101%"
            mx="auto"
            w={{ base: '570px', lg: '570px', md: '570px', sm: '940px' }}
            zIndex="dropdown"
            position="absolute"
            onMouseEnter={departmentsPopoverDisclosure.onOpen}
            onMouseLeave={
              !isClicked
                ? departmentsPopoverDisclosure.onClose
                : departmentsPopoverDisclosure.onOpen
            }
          >
            <Box
              bg="white"
              px={4}
              w={{ base: '570px', lg: '570px', md: '570px', sm: '940px' }}
              zIndex="dropdown"
              position="relative"
              display="flex"
              boxShadow="navbar"
            >
              {Object.entries(departments).map(([category, departs], index) => (
                <Box
                  key={category}
                  flex={1}
                  borderRightWidth={index !== 2 ? 1 : 0}
                  borderRightColor="#D8D8DB"
                  paddingLeft={index === 0 ? 0 : 2}
                  pt={4}
                  pb={4}
                >
                  <Text
                    as="span"
                    fontSize="base"
                    fontWeight="bold"
                    color="black"
                    mb={2}
                  >
                    {category}
                  </Text>
                  {departs.map((department) => (
                    <Box key={category}>
                      <Link href={`/department/${department.id}`}>
                        <Text
                          as="span"
                          fontSize="sm"
                          fontWeight="normal"
                          color="#222222"
                          mb={2}
                          _hover={{
                            color: '#008E14',
                            textDecoration: 'underline',
                          }}
                        >
                          {department.name}
                          <Text
                            as="span"
                            fontSize="xs"
                            fontWeight="normal"
                            ms={1}
                          >
                            ({department.articlesCount})
                          </Text>
                        </Text>
                      </Link>
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
