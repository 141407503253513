import { getPublicRuntimeConfig } from '../config';

function getSlideWebBaseUrl() {
  const { slideWebBaseUrl } = getPublicRuntimeConfig();
  return slideWebBaseUrl;
}

export function createProfileImageRelativeUrl(
  userId: number,
  options?: { version?: number },
) {
  let url = `/api/profile/${userId}/image`;

  if (options?.version) {
    const p = new URLSearchParams();
    p.set('v', String(options.version));
    url = `${url}?${p.toString()}`;
  }

  return url;
}

export function createProfileImageUrl(
  userId: number,
  options?: { version?: number },
) {
  const relativeUrl = createProfileImageRelativeUrl(userId, options);
  return `${getSlideWebBaseUrl()}${relativeUrl}`;
}

export function createProfileRelativeUrl(
  userId: number,
  options?: {
    defaultIsOpenBadgeModal?: boolean;
    asSelf?: boolean;
  },
) {
  let url = options?.asSelf ? `/profile/self` : `/profile/${userId}`;

  if (options?.defaultIsOpenBadgeModal) {
    const p = new URLSearchParams();
    p.set('defaultIsOpenBadgeModal', String(options.defaultIsOpenBadgeModal));
    url = `${url}?${p.toString()}`;
  }

  return url;
}

export function createProfileUrl(
  userId: number,
  options?: {
    defaultIsOpenBadgeModal?: boolean;
    asSelf?: boolean;
  },
) {
  const relativeUrl = createProfileRelativeUrl(userId, options);
  return `${getSlideWebBaseUrl()}${relativeUrl}`;
}

export function createUserName(user: any): string {
  if (!user) return '';
  return user.userName || `${user.lastName ?? ''}${user.firstName ?? ''}`;
}

/**
 * Slide Webにログイン可能なユーザステータスか判定する
 */
export function isUnauthenticatedStatus(userStatus: string): boolean {
  return (
    userStatus !== 'REGISTERED' &&
    userStatus !== 'REVIEWING' &&
    userStatus !== 'INVALID' &&
    userStatus !== 'OK'
  );
}

/**
 * 医師認証、医学生認証が完了してるかどうか
 */
export function isLicenseVerified(user: Express.User | null): boolean {
  // 非ログイン時はfalse
  if (!user) return false;
  // Slideアカウントの場合は一律trueとする
  if (!user.discloseId) return true;
  return user.userStatus?.toUpperCase() === 'OK';
}
