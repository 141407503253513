import { getPublicRuntimeConfig } from '../config';

function isGtagReady() {
  const { gaTrackingId } = getPublicRuntimeConfig();
  return !!gaTrackingId && window && window.gtag;
}

// PV測定
export const pageView = (path: string) => {
  const { gaTrackingId } = getPublicRuntimeConfig();

  if (window && window.gtag) {
    window.gtag('config', gaTrackingId, {
      page_path: path,
    });
  }
};

export type AnalyticsEventProps = {
  category: string;
  action: string;
  label: string | null;
};

/**
 * UAとGA4の両方にイベント送信
 *
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/events?hl=ja#send_events
 */
export const sendAnalyticsEvent = (
  category: string,
  action: string,
  label: string | null,
) => {
  const { gaEnableTracking } = getPublicRuntimeConfig();

  // envファイルのAPP_GA_ENABLE_TRACKINGがtrueであればイベント送信
  if (gaEnableTracking) {
    if (window && window.gtag) {
      window.gtag('event', action, {
        event_category: category,
        event_label: label,
      });
    }
  } else {
    // eslint-disable-next-line no-console
    console.log(
      `GAにイベント送信　category: ${category}, action: ${action}, label: ${label}`,
    );
  }
};

/**
 * GA4のみにイベント送信
 *
 * @see https://developers.google.com/tag-platform/gtagjs/routing
 */
export function sendEventToGA4(
  eventName: string,
  parameters: { [key: string]: string },
) {
  const { gaEnableTracking } = getPublicRuntimeConfig();

  if (!gaEnableTracking) {
    // eslint-disable-next-line no-console
    console.log(
      `GA4にイベント送信 event_name: ${eventName}, parameters: ${JSON.stringify(
        parameters,
      )}`,
    );
    return;
  }

  if (window && window.gtag) {
    window.gtag('event', eventName, {
      ...parameters,
      send_to: 'ga4',
    });
  }
}

/**
 * GAにユーザーID（uidパラメータ）を送信するよう設定または設定解除する。
 *
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/cookies-user-id?hl=ja
 */
export function setUserId(userId: number | null) {
  if (isGtagReady()) {
    window.gtag('set', {
      user_id: userId,
    });
  }
}
