/**
 * スライド投稿行動にフォーカスしてLogRocket録画を行うためのモジュール。
 * {@link startLogRocketRecording}以外はlogRocket.tsと同一インターフェース。
 * 期間限定（終了時期未定）の措置であり、将来的にlogRocket.ts利用に戻す。
 */

import LogRocket from 'logrocket';

export function useLogRocket(_logRocketId: string) {
  // 何もしない
  // logRocket.tsのIFと互換性を保つために用意してある
}

/**
 * 録画の開始
 *
 * サンプリングを行わずに全件録画する。
 */
export function startLogRocketRecording(logRocketId: string) {
  if (!LogRocket || !logRocketId || !window.sessionStorage) {
    return;
  }

  initLogRocket(logRocketId);
}

/**
 * LogRocketの初期化
 *
 * @see https://docs.logrocket.com/reference/init
 */
function initLogRocket(logRocketId: string) {
  LogRocket.init(logRocketId, {
    network: {
      // リクエストのサニタイズ
      requestSanitizer: (request) => {
        request.headers['Authorization'] = undefined;
        request.headers['authorization'] = undefined;
        request.headers['Cookie'] = undefined;
        request.headers['cookie'] = undefined;
        request.body = undefined;
        return request;
      },
      // レスポンスのサニタイズ
      responseSanitizer: (_response) => null,
    },
    // dom関連の設定
    dom: {
      inputSanitizer: true,
      textSanitizer: true,
    },
  });
}

/**
 * LogRocketのユーザーID設定（クリアにも使用する）
 *
 * @see https://docs.logrocket.com/reference/identify
 */
export function setLogRocketUser(
  userId: number | null = null,
  discloseId: string | null = null,
) {
  const uid = String(userId ?? '');
  if (discloseId) {
    LogRocket.identify(uid, { discloseId });
  } else {
    LogRocket.identify(uid);
  }
}
