import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useDisclosure, UseDisclosureReturn } from '@chakra-ui/react';
import { CsrfToken } from '../server/middlewares/csrf';
import {
  hasManageAuthority,
  hasDemoAuthority,
} from '../common/libs/userRoleType';
import { AnnounceContext } from '../hooks/useAnnounce';
import { IntegrationStatus } from '../common/libs/accountIntegrationStatus';
import { isLicenseVerified } from '../common/libs/user';
// import { ProfileDepartmentContext } from '../hooks/useAnnounce';

type User = Express.User | null;

type ValueType = {
  user: User;
  setUser: (user: User) => void;
  isAuthenticated: boolean;
  isLicenseVerified: boolean;
  accountIntegrationStatus: IntegrationStatus | null;
  setAccountIntegrationStatus: (status: IntegrationStatus | null) => void;
  hasManageAuthority: boolean;
  hasDemoAuthority: boolean;
  csrfToken: CsrfToken;
  setCsrfToken: (csrfToken: CsrfToken) => void;
  announces: AnnounceContext;
  setAnnounces: (announces: AnnounceContext) => void;
  userAnnounces: AnnounceContext;
  setUserAnnounces: (announces: AnnounceContext) => void;
  hasUnread: boolean;
  setHasUnread: (hasUnread: boolean) => void;
  searchModalDisclosure: UseDisclosureReturn;
  departmentsPopoverDisclosure: UseDisclosureReturn;
  announceModalDisclosure: UseDisclosureReturn;
  importantNoticeModalDisclosure: UseDisclosureReturn;
  hasImportantNoticeUnread: boolean;
  setHasImportantNoticeUnread: (hasUnread: boolean) => void;
  /* ondemandProfileUpdateModalDisclosure: UseDisclosureReturn;
  showOndemandProfileUpdateModal: boolean;
  setShowOndemandProfileUpdateModal: (
    showOndemandProfileUpdateModal: boolean,
  ) => void;
  isSkippedBeforeOndemandProfileUpdateModal: boolean;
  setIsSkippedBeforeOndemandProfileUpdateModal: (
    isSkippedBeforeOndemandProfileUpdateModal: boolean,
  ) => void;
  idOndemandProfileUpdateModal: number | null;
  setIdOndemandProfileUpdateModal: (id: number | null) => void;
  */
  privacyPolicyAgreement: boolean;
  setPrivacyPolicyAgreement: (privacyPolicyAgreement: boolean) => void;
  termsOfServiceAgreement: boolean;
  setTermsOfServiceAgreement: (termsOfServiceAgreement: boolean) => void;
  // departments: ProfileDepartmentContext;
  // setDepartments: (departments: any) => void;
};

const AppContext = createContext<ValueType | null>(null);

const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User>(null);
  const [csrfToken, setCsrfToken] = useState<CsrfToken>(null);
  const [accountIntegrationStatus, setAccountIntegrationStatus] =
    useState<IntegrationStatus | null>(null);
  const [privacyPolicyAgreement, setPrivacyPolicyAgreement] =
    useState<boolean>(false);
  const [termsOfServiceAgreement, setTermsOfServiceAgreement] =
    useState<boolean>(false);
  const [announces, setAnnounces] = useState<AnnounceContext>({
    hasUnread: false,
    announces: [],
  });
  const [userAnnounces, setUserAnnounces] = useState<AnnounceContext>({
    hasUnread: false,
    announces: [],
  });
  const [hasUnread, setHasUnread] = useState(false);
  const searchModalDisclosure = useSearchModalDisclosure();
  const departmentsPopoverDisclosure = useDepartmentsPopoverDisclosure();
  const announceModalDisclosure = useAnnounceModalDisclosure();
  const importantNoticeModalDisclosure = useImportantNoticeModalDisclosure();
  const [hasImportantNoticeUnread, setHasImportantNoticeUnread] =
    useState(false);
  /* 2023.08.15 オンデマンドプロフィール更新誘導無効化
  const ondemandProfileUpdateModalDisclosure =
    useOndemandProfileUpdateModalDisclosure();
  const [showOndemandProfileUpdateModal, setShowOndemandProfileUpdateModal] =
    useState(false);
  const [
    isSkippedBeforeOndemandProfileUpdateModal,
    setIsSkippedBeforeOndemandProfileUpdateModal,
  ] = useState(false);
  const [idOndemandProfileUpdateModal, setIdOndemandProfileUpdateModal] =
    useState<number | null>(null);
  */
  // const profileDepartmentUpdateModalDisclosure =
  //   useProfileDepartmentUpdateModalDisclosure();
  // const [departments, setDepartments] = useState<ProfileDepartmentContext>([]);

  const value: ValueType = {
    user,
    setUser,
    isAuthenticated: !!user,
    isLicenseVerified: isLicenseVerified(user),
    accountIntegrationStatus,
    setAccountIntegrationStatus,
    hasManageAuthority: hasManageAuthority(user?.userRoles),
    hasDemoAuthority: hasDemoAuthority(user?.userRoles),
    csrfToken,
    setCsrfToken,
    announces,
    setAnnounces,
    userAnnounces,
    setUserAnnounces,
    hasUnread,
    setHasUnread,
    searchModalDisclosure,
    departmentsPopoverDisclosure,
    announceModalDisclosure,
    importantNoticeModalDisclosure,
    hasImportantNoticeUnread,
    setHasImportantNoticeUnread,

    /* 2023.08.15 オンデマンドプロフィール更新誘導無効化
    ondemandProfileUpdateModalDisclosure,
    showOndemandProfileUpdateModal,
    setShowOndemandProfileUpdateModal,
    isSkippedBeforeOndemandProfileUpdateModal,
    setIsSkippedBeforeOndemandProfileUpdateModal,
    idOndemandProfileUpdateModal,
    setIdOndemandProfileUpdateModal,
    */
    privacyPolicyAgreement,
    setPrivacyPolicyAgreement,
    termsOfServiceAgreement,
    setTermsOfServiceAgreement,
    // departments,
    // setDepartments,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

function useSearchModalDisclosure(): ReturnType<typeof useDisclosure> {
  const disclosure = useDisclosure();
  const onOpen = useCallback(() => {
    disclosure.onOpen();
  }, [disclosure]);
  return {
    ...disclosure,
    onOpen,
  };
}

function useDepartmentsPopoverDisclosure(): ReturnType<typeof useDisclosure> {
  const disclosure = useDisclosure();
  const onOpen = useCallback(() => {
    disclosure.onOpen();
  }, [disclosure]);
  return {
    ...disclosure,
    onOpen,
  };
}

function useAnnounceModalDisclosure(): ReturnType<typeof useDisclosure> {
  const disclosure = useDisclosure();
  const onOpen = useCallback(() => {
    disclosure.onOpen();
  }, [disclosure]);
  return {
    ...disclosure,
    onOpen,
  };
}

function useImportantNoticeModalDisclosure(): ReturnType<typeof useDisclosure> {
  const disclosure = useDisclosure();
  const onOpen = useCallback(() => {
    disclosure.onOpen();
  }, [disclosure]);
  return {
    ...disclosure,
    onOpen,
  };
}

/* 2023.08.15 オンデマンドプロフィール更新誘導無効化
function useOndemandProfileUpdateModalDisclosure(): ReturnType<
  typeof useDisclosure
> {
  const disclosure = useDisclosure();
  const onOpen = useCallback(() => {
    disclosure.onOpen();
  }, [disclosure]);
  return {
    ...disclosure,
    onOpen,
  };
}
*/

const useAppContext = () => useContext(AppContext)!;

export { AppContext, AppContextProvider, useAppContext };
