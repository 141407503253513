import { useCallback } from 'react';
import monitoring from '../common/libs/monitoring';
import getConfig from '../common/config';
import Firebase from '../common/libs/firebase';

export default function useIdApiClient() {
  const {
    publicRuntimeConfig: { idBaseUrl },
  } = getConfig();

  /**
   * ID基盤のAPIラッパー
   */
  const fetchWrapper = async (input: RequestInfo, init?: RequestInit) => {
    if (typeof input === 'string') {
      input = `${idBaseUrl}/api/${input}`;
    }

    let options: RequestInit | undefined;
    if (init?.method) {
      const additionalHeaders = {
        'Content-Type': 'application/json',
      } as any;
      const token = await Firebase.getIdToken();
      if (token) {
        additionalHeaders['Authorization'] = `Bearer ${token}`;
      }
      options = {
        ...init,
        credentials: 'include',
        headers: {
          ...init.headers,
          ...additionalHeaders,
        },
      };
    } else {
      options = init;
    }

    const res = await fetch(input, options);
    if (500 <= res.status && res.status <= 599) {
      const notifyMessage = await res
        .json()
        .then((data) => {
          return JSON.stringify(data?.error);
        })
        .catch(() => {
          return res.statusText;
        });
      monitoring.notify({
        name: `API ERROR DETECTED (${res.status})`,
        message: notifyMessage,
      });
    }

    return res;
  };

  /**
   * ID基盤のWebページへ遷移
   */
  const proceedIdPage = async (path: string, discloseId?: string | null) => {
    // ID基盤ログイン（認証済みCookieを更新するため必ず呼ぶ）
    let option: RequestInit = {
      method: 'POST',
    };
    if (discloseId) {
      option.body = JSON.stringify({ discloseId });
    }

    const res = await fetchWrapper('login', option);
    if (res.status === 401) {
      console.error(`ID Login error; status = ${res.status}`);
      if (discloseId) {
        // QA／Antaaアカウントありの場合で認証エラー検出時は再認証へリダイレクト
        window.location.href = `/logout?title=認証期限が切れました。再度ログインしてください。&redirectUrl=${encodeURIComponent(
          idBaseUrl + path,
        )}`;
        return;
      }
    } else if (res.status !== 200) {
      // 認証エラー以外のエラーは遷移先画面で表示するため無視する
      console.error(`ID Login error; status = ${res.status}`);
    }

    // ID基盤の画面へ遷移
    window.location.href = `${idBaseUrl}${path}`;
  };

  const idApiClient = useCallback(fetchWrapper, [idBaseUrl]);

  return { idApiClient, proceedIdPage };
}
