/**
 * エラー状態
 */
export const AuthErrorCondVal = {
  ERROR: 'error',
  FATAL: 'fatal',
  NO_REGIST: 'noRegist',
  REGISTER_NOT_COMPLETE: 'registerNotComplete',
  ACCOUNT_DISABLE: 'accountDisable',
  USE_INTEGRATED_ACCOUNT: 'useIntegratedAccount',
} as const;
export type AuthErrorCond =
  | (typeof AuthErrorCondVal)[keyof typeof AuthErrorCondVal]
  | null;

export const AuthErrorSubCondVal = {
  SLIDE_ACCOUNT_EXISTS: 'slideAccountExists',
} as const;
export type AuthErrorSubCond =
  | (typeof AuthErrorSubCondVal)[keyof typeof AuthErrorSubCondVal]
  | null;

/**
 * エラーベースクラス
 *
 * 注）instanceof によるクラス判定が期待どおりに動作しない場合があるためisAntaaErrorプロパティの有無で判定を行う
 */
export class AntaaError extends Error {
  isAntaaError: boolean = true;
  status: number = 0;
  cond: AuthErrorCond;
  subCond: AuthErrorSubCond;

  constructor(
    message: string,
    status?: number,
    cond?: AuthErrorCond,
    subCond?: AuthErrorSubCond,
  ) {
    super(message);
    Object.setPrototypeOf(this, AntaaError.prototype);
    this.status = status ?? 500;
    this.cond = cond ?? AuthErrorCondVal.ERROR;
    this.subCond = subCond ?? null;
  }
}

/**
 * オブジェクトがAntaaErrorおよびその派生クラスであるか判定する
 *
 * @param obj 判定対象のオブジェクト
 * @returns オブジェクトがAntaaErrorおよびその派生クラスであるはtrueを、そうでない場合はfalseを返す
 */
export const isAntaaError = (obj: any) => {
  return obj && 'isAntaaError' in obj;
};

/**
 * パラメーターエラー
 */
export class ParameterError extends AntaaError {
  constructor(message: string, cond?: AuthErrorCond) {
    super(message, 400, cond);
    Object.setPrototypeOf(this, ParameterError.prototype);
  }
}

/**
 * 認証エラー
 */
export class AuthError extends AntaaError {
  constructor(message: string, cond?: AuthErrorCond) {
    super(message, 401, cond);
    Object.setPrototypeOf(this, AuthError.prototype);
  }
}

/**
 * 認可エラー
 */
export class ForbiddenError extends AntaaError {
  constructor(message: string, cond?: AuthErrorCond) {
    super(message, 403, cond);
    Object.setPrototypeOf(this, ForbiddenError.prototype);
  }
}

/**
 * CSRFトークン検証エラー
 */
export class CsrfVerifyError extends AntaaError {
  constructor(message: string, cond?: AuthErrorCond) {
    super(message, 401, cond ?? AuthErrorCondVal.FATAL);
    Object.setPrototypeOf(this, CsrfVerifyError.prototype);
  }
}

/**
 * アカウント未登録エラー
 */
export class NoRegistError extends AntaaError {
  constructor(message: string, cond?: AuthErrorCond) {
    super(message, 404, cond ?? AuthErrorCondVal.NO_REGIST);
    Object.setPrototypeOf(this, NoRegistError.prototype);
  }
}

/**
 * Not Foundエラー
 */
export class NotFoundError extends AntaaError {
  constructor(message: string, cond?: AuthErrorCond) {
    super(message, 404, cond);
    Object.setPrototypeOf(this, NotFoundError.prototype);
  }
}

/**
 * アカウント登録未完了エラー
 */
export class RegisterNotCompleteError extends AntaaError {
  constructor(message: string, subCond?: AuthErrorSubCond) {
    super(message, 403, AuthErrorCondVal.REGISTER_NOT_COMPLETE, subCond);
    Object.setPrototypeOf(this, RegisterNotCompleteError.prototype);
  }
}

/**
 * アカウント無効エラー
 */
export class AccountDisableError extends AntaaError {
  constructor(message: string, subCond?: AuthErrorSubCond) {
    super(message, 403, AuthErrorCondVal.ACCOUNT_DISABLE, subCond);
    Object.setPrototypeOf(this, AccountDisableError.prototype);
  }
}

/**
 * 統合済みアカウント誘導エラー
 */
export class UseIntegratedAccountError extends AntaaError {
  constructor(message: string, subCond?: AuthErrorSubCond) {
    super(message, 403, AuthErrorCondVal.USE_INTEGRATED_ACCOUNT, subCond);
    Object.setPrototypeOf(this, UseIntegratedAccountError.prototype);
  }
}

/**
 * サービスエラー（上記に該当しないもの）
 */
export class ServiceError extends AntaaError {
  constructor(message: string, cond?: AuthErrorCond) {
    super(message, 500, cond ?? AuthErrorCondVal.FATAL);
    Object.setPrototypeOf(this, ServiceError.prototype);
  }
}
