import React, { useEffect } from 'react';
import {
  Box,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  UseDisclosureReturn,
} from '@chakra-ui/react';
import useBadge from '../hooks/useBadge';
import {
  BADGE_CRITERIA,
  Badge,
  BadgeTypeConf,
  BadgeSeriesEnum,
  CommentBadgeTypeEnum,
  PostSlideBadgeTypeEnum,
} from '../common/libs/badgeType';
import { sendAnalyticsEvent } from '../common/libs/googleAnalytics';
import BadgeIcon from './BadgeIcon';

function BadgeLabel({ label }: { label: string }) {
  return (
    <Text fontSize="md" fontWeight="bold" color={'white'}>
      {label}
    </Text>
  );
}

function BadgeGrid({
  seriesId,
  typeId,
  badgeTypeConf,
  hasBadge,
}: {
  seriesId: BadgeSeriesEnum;
  typeId: PostSlideBadgeTypeEnum | CommentBadgeTypeEnum;
  badgeTypeConf: BadgeTypeConf;
  hasBadge: Badge | null;
}) {
  const judgeHave = (grade: number) => {
    if (badgeTypeConf.type === 'stepup') {
      // ステップアップタイプの場合は、上位のバッジを保持していれば下位のバッジも保持している物とみなす
      return hasBadge ? grade <= hasBadge.grade : false;
    } else {
      return hasBadge ? grade === hasBadge.grade : false;
    }
  };

  return (
    <SimpleGrid columns={4} spacing="10px">
      {badgeTypeConf.badges.map((badge, i) => (
        <Box key={i} textAlign="center">
          {!!badge && (
            <>
              <BadgeIcon
                seriesId={seriesId}
                typeId={typeId}
                grade={badge.grade}
                achieved={judgeHave(badge.grade)}
              />
              <Text
                fontSize={'sm'}
                align={'center'}
                color={judgeHave(badge.grade) ? 'black' : 'lightgray'}
              >
                {badge.label ? badge.label : `${badge.threshold}回`}
              </Text>
            </>
          )}
        </Box>
      ))}
    </SimpleGrid>
  );
}

function BadgeList({ hasBadges }: { hasBadges: Badge[][] }) {
  if (hasBadges.length === 0) {
    return <></>;
  }

  return (
    <>
      {BADGE_CRITERIA.map((series, si) =>
        series.map((type, ti) => (
          <Box key={ti} mb={4}>
            <Center width={'100%'} bgColor={type.labelColor} mb={2}>
              <BadgeLabel label={`${type.label}`} />
            </Center>
            <BadgeGrid
              seriesId={si}
              typeId={ti}
              badgeTypeConf={type}
              hasBadge={hasBadges[si][ti]}
            />
          </Box>
        )),
      )}
    </>
  );
}

export default function BadgeModal({
  userId,
  disclosure,
}: {
  userId: number;
  disclosure: UseDisclosureReturn;
}) {
  const { badges, fetchBadges } = useBadge();

  // モーダル開閉時の処理
  useEffect(() => {
    if (disclosure.isOpen) {
      fetchBadges(userId);
    }
  }, [disclosure.isOpen, fetchBadges, userId]);

  return (
    <Modal size={'lg'} onClose={disclosure.onClose} isOpen={disclosure.isOpen}>
      <Box pos="relative">
        <ModalOverlay />
        <ModalContent pos={'absolute'} right={[null, null, 10]}>
          <ModalHeader>
            <Center mt={4}>バッジ一覧</Center>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={4}>
            <BadgeList hasBadges={badges} />
          </ModalBody>
        </ModalContent>
      </Box>
    </Modal>
  );
}
