import passport from 'passport';
import {
  ApiNextHandler,
  ApiRequest,
  ApiResponse,
  InnerApiResponse,
} from '../libs/connect';
import {
  AntaaError,
  AuthErrorCond,
  AuthErrorSubCond,
  isAntaaError,
} from '../libs/error';

/**
 * API固有のレスポンス
 */
export class ApiPeculiarResponse<T = object> {
  /**
   * コンストラクタ
   *
   * @param props API固有のレスポンスデーター
   */
  constructor(props: T) {
    Object.assign(this, props);
  }
}

/**
 * API共通レスポンス
 */
interface ApiCommonResponse {
  resultInfo: {
    status: number;
    messageInfo?: {
      type?: string;
      id?: string;
      message: string;
      cond?: AuthErrorCond;
      subCond?: AuthErrorSubCond;
      details?: {
        type?: string;
        id?: string;
        message: string;
        howToDeal?: string;
      };
    };
  };
}

/**
 * APIのレスポンスボディを生成する
 *
 * @param status レスポンスステータス
 * @param err エラー
 * @param peculiarRes API固有レスポンス
 * @returns 生成したAPIのレスポンスボディ
 */
const createResponseBody = (
  status: number,
  err: Error | null,
  peculiarRes: ApiPeculiarResponse | null = null,
) => {
  const resBody: ApiCommonResponse = { resultInfo: { status } };
  Object.assign(resBody, peculiarRes);
  if (err) {
    resBody.resultInfo.messageInfo = {
      type: 'Error',
      message: isAntaaError(err) ? err.message : 'Server Error',
    };
    if (isAntaaError(err)) {
      resBody.resultInfo.messageInfo.cond = (err as AntaaError).cond;
      if ('subCond' in err && (err as AntaaError).subCond) {
        resBody.resultInfo.messageInfo.subCond = (err as AntaaError).subCond;
      }
    }
  }

  return resBody;
};

/**
 * APIの正常レスポンスを設定する
 *
 * @param req リクエスト
 * @param res レスポンス
 * @param status レスポンスステータス
 * @param peculiarRes API固有レスポンス
 */
export const setSuccessResponse = (
  req: ApiRequest,
  res: ApiResponse<any>,
  status?: number | null,
  peculiarRes: ApiPeculiarResponse | null = null,
) => {
  if (res.err) {
    req.log.debug(`<setSuccessResponse> err = ${res.err}`);
    return;
  }
  res.json(createResponseBody(status ?? 200, null, peculiarRes));
};

/**
 * APIのエラーレスポンスを設定する
 *
 * @param req リクエスト
 * @param res レスポンス
 * @param err エラー
 * @param status レスポンスステータス
 * @param peculiarRes API固有レスポンス
 */
export const setErrorResponse = (
  req: ApiRequest,
  res: ApiResponse<any>,
  err: Error | null,
  status: number | null = null,
  peculiarRes: ApiPeculiarResponse | null = null,
) => {
  let code = 500;
  if (err && 'status' in err) {
    req.log.debug(
      `<setErrorResponse> err is AntaaError; status = ${
        (err as AntaaError).status
      }`,
    );
    code = (err as AntaaError).status;
  }
  if (status) {
    req.log.debug(`<setErrorResponse> status = ${status}`);
    code = status;
  }
  req.log.error(
    `<API ERROR> code = ${code}; err = ${err} (${err?.constructor?.name})`,
  );
  res.status(code).json(createResponseBody(code, err, peculiarRes));
};

/**
 * 関連サービス向け内部API前処理
 *
 * @param req リクエスト
 * @param res レスポンス
 * @param next スタックの次要素
 */
export const innerApiPreProc = async (
  req: ApiRequest,
  res: InnerApiResponse<any>,
  next: ApiNextHandler,
) => {
  req.log.debug('>> innerApiPreProc - START');
  passport.authenticate(
    'jwt',
    { session: false },
    async (
      err: Error | null,
      user: Express.User | undefined,
      info: Error | null,
    ) => {
      req.log.debug('>>> innerApiPreProc (CallBack) - custom callback');
      req.log.debug(
        `API AUTH: custom callback; err = ${err}, user = ${JSON.stringify(
          user,
        )}, info = ${info}`,
      );

      if (err) {
        req.log.error(`API AUTH ERROR; err = ${err}; info = ${info}`);
        setErrorResponse(req, res, err, null, res?.payload);
        return;
      }
      if (!user) {
        // トークンの検証エラー時はuser=falseで、infoにエラー情報が設定される
        req.log.error(`API AUTH ERROR; user is null; info = ${info}`);
        setErrorResponse(req, res, info, 401, res?.payload);
        return;
      }
      req.user = user;
      req.log.debug(`API AUTH; sucess; req.user = ${JSON.stringify(req.user)}`);

      // API実処理
      req.log.debug('-- innerApiPreProc (CallBack) - Before next()');
      await next();
      req.log.debug('-- innerApiPreProc (CallBack) - After next()');
      req.log.debug('<<< innerApiPreProc (CallBack) - fin.');
    },
  )(req, res, next);
  req.log.debug('<< innerApiPreProc - END');
};

/**
 * 関連サービス向け内部API後処理
 *
 * @param req リクエスト
 * @param res レスポンス
 * @param _next スタックの次要素
 */
export const innerApiPostProc = async (
  req: ApiRequest,
  res: InnerApiResponse<any>,
  _next: ApiNextHandler,
) => {
  req.log.debug('>> innerApiPostProc - START');

  // APIレスポンスの組み立て
  setSuccessResponse(req, res, null, res?.payload);

  req.log.debug('<< innerApiPostProc - END');
};

/**
 * APIエラーハンドラ
 *
 * @param err エラー
 * @param req リクエスト
 * @param res レスポンス
 * @param _next スタックの次要素
 */
export const apiOnError = (
  err: Error,
  req: ApiRequest,
  res: InnerApiResponse<any>,
  _next: ApiNextHandler,
) => {
  req.log.debug('>> apiOnError - START');
  req.log.error(`apiOnError - err = ${err}`);
  setErrorResponse(req, res, err);
  req.log.debug('<< apiOnError - END');
};

/**
 * APIのパスがマッチしなかった場合のハンドラ
 *
 * @param req リクエスト
 * @param res レスポンス
 */
export const apiOnNoMatch = (req: ApiRequest, res: InnerApiResponse<any>) => {
  req.log.debug('>> apiOnNoMatch - START');
  req.log.error('apiOnNoMatch');
  res.status(404).end('Page is not found');
  req.log.debug('<< apiOnNoMatch - END');
};
