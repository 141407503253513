import React, { useCallback } from 'react';
import useApiClient from '../hooks/useApiClient';
import monitoring from '../common/libs/monitoring';
import { useAppContext } from '../contexts/app';

/**
 * 重要通知に関するHooks
 */
export default function useImportantNotice() {
  const { apiClient } = useApiClient();
  const { hasImportantNoticeUnread, setHasImportantNoticeUnread } =
    useAppContext();

  const fetchImportantNotice = useCallback(async () => {
    try {
      const res = await apiClient('/api/important-notice', {
        method: 'GET',
      });
      if (res.ok) {
        const data = await res.json();
        setHasImportantNoticeUnread(data.hasUnread);
      } else {
        throw new Error();
      }
    } catch (e) {
      monitoring.notify({
        name: `API ERROR DETECTED ${e}`,
        message: '重要通知の取得に失敗しました。',
      });
    }
  }, [apiClient, setHasImportantNoticeUnread]);

  const readImportantNotice = useCallback(async () => {
    try {
      const res = await apiClient('/api/important-notice/read', {
        method: 'POST',
      });
      if (!res.ok) {
        throw new Error();
      }
    } catch (e) {
      monitoring.notify({
        name: `API ERROR DETECTED ${e}`,
        message: '重要通知の読み込み通知に失敗しました。',
      });
    }
  }, [apiClient]);

  return {
    fetchImportantNotice,
    readImportantNotice,
    hasImportantNoticeUnread,
    setHasImportantNoticeUnread,
  };
}
