import { useCallback, useEffect, useState } from 'react';
import { isAccountIntegrated } from '../common/libs/accountIntegrationStatus';
import monitoring from '../common/libs/monitoring';
import { useAppContext } from '../contexts/app';
import useApiClient from '../hooks/useApiClient';
import AccountIntegrationInductionModal, {
  AccountIntegrationInductionModalProps,
} from './AccountIntegrationInductionModal';

export default function AccountIntegrationContainer() {
  const { isAuthenticated, hasManageAuthority, hasDemoAuthority } =
    useAppContext();
  const { apiClient } = useApiClient();
  const [
    shouldShowAccountIntegrationInductionModal,
    setShouldShowAccountIntegrationInductionModal,
  ] = useState<boolean>(false);
  const [
    AccountIntegrationInductionModalConfig,
    setAccountIntegrationInductionModalConfig,
  ] = useState<AccountIntegrationInductionModalProps>({
    isOpen: false,
    onClose: () => {},
  });

  const callAccountIntegrationStatusApi = useCallback(async () => {
    const res = await apiClient('/api/account/integration/status', {
      method: 'GET',
    });
    if (res.ok) {
      const data = await res.json();
      if (!isAccountIntegrated(data.integrationStatus)) {
        setShouldShowAccountIntegrationInductionModal(true);
      }
    } else {
      throw new Error();
    }
  }, [apiClient]);

  useEffect(() => {
    if (isAuthenticated && !hasManageAuthority && !hasDemoAuthority) {
      callAccountIntegrationStatusApi().catch((e) =>
        monitoring.notify({
          name: `API ERROR DETECTED ${e}`,
          message: 'アカウント統合状態の取得に失敗しました。',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, callAccountIntegrationStatusApi]);

  useEffect(() => {
    new Promise(async () => {
      if (shouldShowAccountIntegrationInductionModal) {
        await new Promise<boolean>((resolve) => {
          setAccountIntegrationInductionModalConfig({
            isOpen: true,
            onClose: resolve,
          });
        });
        setAccountIntegrationInductionModalConfig({
          isOpen: false,
          onClose: () => {},
        });
        await setShouldShowAccountIntegrationInductionModal(false);
      }
    });
  }, [shouldShowAccountIntegrationInductionModal]);

  return (
    <>
      <AccountIntegrationInductionModal
        {...AccountIntegrationInductionModalConfig}
      />
    </>
  );
}
