import React from 'react';
import {
  Button,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  HStack,
  VStack,
  Link,
} from '@chakra-ui/react';
import NextImage from 'next/image';
import { sendAnalyticsEvent } from '../common/libs/googleAnalytics';
import { getPublicRuntimeConfig } from '../common/config';
import useIdApiClient from '../hooks/useIdApiClient';
import useApiClient from '../hooks/useApiClient';
import Firebase from '../common/libs/firebase';
import { useAppContext } from '../contexts/app';
import { useLogout } from '../hooks/useLogout';

export type AccountIntegrationInductionModalProps = {
  isOpen: boolean;
  onClose: (result: any) => void; // [memo] resultには更新して閉じた場合はtrueを、×ボタンで閉じた場合はfalseを渡す想定
};

export default function AccountIntegrationInductionModal(
  props: AccountIntegrationInductionModalProps,
) {
  const { idApiClient, proceedIdPage } = useIdApiClient();
  const { apiClient } = useApiClient();
  const { slideWebBaseUrl, qaWebBaseUrl } = getPublicRuntimeConfig();
  const { user } = useAppContext();
  const { logout } = useLogout();

  const onClose = () => {
    sendAnalyticsEvent('account_integration_induction', 'click', 'close');
  };

  const proceedAccountIntegration = async () => {
    sendAnalyticsEvent('account_integration_induction', 'click', 'proceed');

    // ID基盤ログイン
    // ログイン永続化によりID基盤に未ログインの可能性があるため遷移前にログインAPIをコールする
    if (await Firebase.getIdToken()) {
      const res = await idApiClient('login', {
        method: 'POST',
      });
      // eslint-disable-next-line no-console
      console.log(`@@@@ ID LOGIN; res = ${JSON.stringify(res)}`);
    }

    // アカウント統合セッションの生成
    const res = await apiClient('/api/account/integration/create-session', {
      method: 'POST',
    });
    const data = await res.json();
    // eslint-disable-next-line no-console
    console.log(`@@@@ ID CREATE SESSION; data = ${data}`);

    // アカウント統合フローへ
    const { idBaseUrl } = getPublicRuntimeConfig();
    const p = new URLSearchParams();
    p.set('from_service', 'slide');
    if (data.uid) {
      p.set('uid', String(data.uid));
    }
    if (data.sid) {
      p.set('sid', String(data.sid));
    }
    proceedIdPage(`/account-integration?${p.toString()}`, user?.discloseId);
  };

  const onClickQaLink = () => {
    sendAnalyticsEvent('account_integration_induction', 'click', 'qa_link');
  };

  const onClickSlideLink = () => {
    sendAnalyticsEvent('account_integration_induction', 'click', 'slide_link');
  };

  return (
    <Modal
      size={'md'}
      blockScrollOnMount={false}
      isOpen={props.isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent borderRadius="lg">
        <ModalHeader>
          <Center>
            <HStack>
              <NextImage
                src="/images/icon_antaa_white_small.png"
                width={56}
                height={56}
              />
              <Text fontSize={'xx-large'} fontWeight={'bold'}>
                {'QA x Slide'}
              </Text>
            </HStack>
          </Center>
          <VStack spacing={0}>
            <Text fontSize={'xl'} fontWeight={'bold'}>
              ※重要なお知らせ
            </Text>
            <Text fontSize={'xl'} fontWeight={'bold'}>
              アカウント統合のお願い
            </Text>
          </VStack>
        </ModalHeader>

        <ModalBody mx="20px">
          <Text>
            これまで、Antaaでは
            <Link
              href={qaWebBaseUrl}
              target="_blank"
              isExternal
              onClick={onClickQaLink}
            >
              Antaa QA
            </Link>
            と
            <Link
              href={slideWebBaseUrl}
              target="_blank"
              isExternal
              onClick={onClickSlideLink}
            >
              Antaa Slide
            </Link>
            の2つのサービスを別々で提供していましたが、この度Antaaという1つのサービスに統合することにいたしました。
          </Text>
          <Text>
            Antaa QAとAntaa
            Slideのアカウントを連携していただくことで、より便利にサービスをお使いいただけるようになります。
          </Text>

          <Center>
            <VStack w="full" spacing={5} mt={10} mb={10}>
              <Button
                colorScheme="brand"
                fontSize="md"
                w="full"
                borderRadius="20px"
                onClick={proceedAccountIntegration}
              >
                アカウント統合へ進む
              </Button>
              <Link onClick={() => logout()} color={'gray.500'}>
                ログアウト
              </Link>
            </VStack>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
