export type UserRoleName = 'ROLE_ADMIN' | 'ROLE_DEMO';

export type UserRoleType = UserRoleName | null;
export type UserRole = {
  name: UserRoleName;
  label: string;
};
export const userRoleValues: UserRole[] = [
  { name: 'ROLE_ADMIN', label: '管理者' },
  { name: 'ROLE_DEMO', label: 'デモ' },
];

export function hasManageAuthority(userRoles?: Array<UserRoleType>) {
  return !!userRoles?.includes('ROLE_ADMIN');
}

export function hasDemoAuthority(userRoles?: Array<UserRoleType>) {
  return !!userRoles?.includes('ROLE_DEMO');
}
