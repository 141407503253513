import { initializeApp, getApps, FirebaseApp } from 'firebase/app';
import {
  User,
  getAuth as firebaseGetAuth,
  onIdTokenChanged,
  signOut,
} from 'firebase/auth';
import { getPublicRuntimeConfig } from '../config';
export {
  type AuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  createUserWithEmailAndPassword,
  getAdditionalUserInfo,
  getRedirectResult,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
} from 'firebase/auth';

class Firebase {
  /**
   * Firebaseアプリ
   */
  private firebaseApp: FirebaseApp | null = null;

  /**
   * ログイン済みユーザー
   */
  private user: User | null = null;

  /**
   * アクセストークンのリフレッシュ要否
   */
  private needTokenRefresh: boolean = false;

  /**
   * コンストラクタ
   */
  constructor() {}

  /**
   * Firebaseの初期化
   * @param cb Firebaseアクセストークン変更時のコールバック関数
   */
  public initFirebase(cb?: (user: User | null) => void) {
    if (getApps().length === 0) {
      const credential = getPublicRuntimeConfig().firebaseCredential;
      if (credential) {
        this.firebaseApp = initializeApp(credential);

        // サインイン、サインアウト、トークン更新を検出しアクセストークンを更新
        // @see https://firebase.google.com/docs/reference/node/firebase.auth.Auth#onidtokenchanged
        onIdTokenChanged(firebaseGetAuth(), async (user) => {
          // eslint-disable-next-line no-console
          if (user) {
            this.user = user;
          } else {
            this.user = null;
          }
          cb?.(user);
        });
      }
    }
  }

  /**
   * Firebase Authenticationへのリファレンスを取得
   *
   * @returns リファレンス
   */
  public getAuth() {
    if (!this.firebaseApp) {
      this.initFirebase();
    }
    return firebaseGetAuth();
  }
  /**
   * アクセストークンの取得
   *
   * @returns アクセストークン（非ログイン時はnullを返す）
   * @see https://firebase.google.com/docs/reference/node/firebase.User#getidtoken
   * @note アクセストークンの期限が切れている場合はリフレッシュしたものを返す
   */
  public getIdToken() {
    return this.user ? this.user.getIdToken() : null;
  }

  /*
  public isTokenExpired() {
    return (tokenExpiredAt !== null && moment().unix() > tokenContext.tokenExpiredAt);
  }

  public async refreshTokenIfExpired(force = false) {
    if (!isTokenExpired() && !force) {
      return null;
    }
    const token = await refreshFirebaseToken();
    setToken(token || null);
    return token || null;
  }
  */

  /**
   * ログイン
   */
  public setNeedTokenRefresh() {
    // eslint-disable-next-line no-console
    this.needTokenRefresh = true;
  }

  /**
   * ログアウト
   */
  public logout() {
    // eslint-disable-next-line no-console
    this.needTokenRefresh = false;
    const auth = this.getAuth();
    if (auth) {
      signOut(auth)
        .then(() => {
          // NOP
          // eslint-disable-next-line no-console
        })
        .catch((error) => {
          // NOP
        });
    }
  }

  /**
   * Firebaseのエラーコードをメッセージ文字列に変換する
   *
   * @param code Firebaseのエラーコード
   * @returns メッセージ文字列
   */
  public getErrorMessage(code: string) {
    switch (code) {
      /* 新規登録 */
      case 'auth/weak-password':
        return 'パスワードは6文字以上で設定してください';
      case 'auth/email-already-in-use':
        return '既に登録されているメールアドレスです';
      case 'auth/account-exists-with-different-credential':
        return '異なるログイン方法で登録されているメールアドレスです';
      /* ログイン */
      case 'auth/user-not-found':
      case 'auth/wrong-password':
        return 'メールアドレスまたはパスワードが正しくありません';
      /* ログイン・新規登録共通 */
      case 'auth/invalid-email':
        return 'メールアドレスの形式が間違っています';
      case 'auth/too-many-requests':
        return '現在ご利用頂けません。しばらく経ってから再度お試しください';
      case 'auth/user-cancelled':
        return 'キャンセルされました';
      case 'facebook/nocredential':
        return 'アカウント登録に失敗しました';
      case 'idApi/signup-error':
        return 'アカウント登録に失敗しました';
      case 'idApi/login-error':
        return 'ログインに失敗しました';
      case 'idApi/send-verify-mail-error':
        return '確認メールの送信に失敗しました';
      case 'idApi/too-many-verify-mail-requests':
        return '確認メールをすでに送信済みです。再度送信する場合は時間をあけてお試しください';
      case 'idApi/call-error':
        return '処理に失敗しました';
      case null:
        return '';
      default:
        return '処理に失敗しました';
    }
  }
}

const firebaseInstance = new Firebase();

export default firebaseInstance;

/* import { initializeApp } from 'firebase/app';

export const initFirebase = (credential: object) => {
  initializeApp(credential);
};*/
