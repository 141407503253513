import React, { useCallback, useState } from 'react';
import useApiClient from '../hooks/useApiClient';
import monitoring from '../common/libs/monitoring';
import { Badge } from '../common/libs/badgeType';

/**
 * バッジに関するHooks
 */
export default function useBadge() {
  const { apiClient } = useApiClient();
  const [badges, setBadges] = useState<Badge[][]>([]);

  const fetchBadges = useCallback(
    async (userId: number) => {
      try {
        const res = await apiClient(`/api/profile/${userId}/badges`, {
          method: 'GET',
        });
        if (res.ok) {
          const badges = await res.json();
          setBadges(badges);
        } else {
          throw new Error();
        }
      } catch (e) {
        monitoring.notify({
          name: `API ERROR DETECTED ${e}`,
          message: 'バッジ一覧の取得に失敗しました。',
        });
      }
    },
    [apiClient, setBadges],
  );

  return {
    badges,
    fetchBadges,
  };
}
