import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  HStack,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  TextProps,
  useBreakpointValue,
} from '@chakra-ui/react';
import { CloseIcon, SearchIcon, ChevronRightIcon } from '@chakra-ui/icons';
import NextLink from 'next/link';
import { useAppContext } from '../contexts/app';
import useApiClient from '../hooks/useApiClient';
import { sendAnalyticsEvent } from '../common/libs/googleAnalytics';
import monitoring from '../common/libs/monitoring';
import SearchArticlesForm from './SearchArticlesForm';

type SuggestType =
  | 'popularKeywords'
  | 'searchHistoryKeywords'
  | 'prefixedWithKeywords'
  | 'none';

function SuggestTitle({
  suggestType,
  ...restProps
}: { suggestType: SuggestType } & TextProps) {
  let title = '';
  switch (suggestType) {
    case 'searchHistoryKeywords':
      title = '検索履歴';
      break;
    case 'popularKeywords':
      title = '人気キーワード';
      break;
    case 'prefixedWithKeywords':
    case 'none':
      return null;
  }
  return (
    <Text fontWeight="bold" color="gray" {...restProps}>
      {title}
    </Text>
  );
}

function SuggestKeyword({
  suggestKeyword,
  gaEventLabel,
  icon,
  iconAriaLabel,
  onClickIcon,
}: {
  suggestKeyword: string;
  gaEventLabel: string;
  icon: React.ReactElement<string>;
  iconAriaLabel: string;
  onClickIcon?: (suggestKeyword: string) => void;
}) {
  const { searchModalDisclosure } = useAppContext();

  return (
    <NextLink href={`/search?q=${encodeURIComponent(suggestKeyword)}`} passHref>
      <Link
        display="block"
        color="linkText"
        px={4}
        _hover={{
          bg: 'blackAlpha.50',
          textDecoration: 'none',
        }}
        onClick={() => {
          sendAnalyticsEvent('slide', 'index', gaEventLabel);
          searchModalDisclosure.onClose();
        }}
      >
        <HStack ps={1}>
          <SearchIcon color="blackAlpha.900" />
          <Text flex={1} noOfLines={1} fontSize="md" ps={2}>
            {suggestKeyword}
          </Text>
          <IconButton
            flexShrink={0}
            aria-label={iconAriaLabel}
            icon={icon}
            color="green"
            variant="unstyled"
            rounded="full"
            _hover={{
              bg: 'blackAlpha.50',
            }}
            onClick={(e) => {
              if (onClickIcon) {
                e.preventDefault();
                e.stopPropagation();
                onClickIcon(suggestKeyword);
              }
            }}
          />
        </HStack>
      </Link>
    </NextLink>
  );
}

function SuggestKeywords({
  suggestKeywords,
  gaEventLabel,
  icon,
  iconAriaLabel,
  onClickIcon,
}: {
  suggestKeywords: string[];
  gaEventLabel: string;
  icon: React.ReactElement<string>;
  iconAriaLabel: string;
  onClickIcon?: (suggestKeyword: string) => void;
}) {
  if (suggestKeywords.length === 0) {
    return null;
  }
  return (
    <>
      {suggestKeywords.map((keyword, i) => (
        <SuggestKeyword
          key={i}
          suggestKeyword={keyword}
          gaEventLabel={gaEventLabel}
          icon={icon}
          iconAriaLabel={iconAriaLabel}
          onClickIcon={onClickIcon}
        />
      ))}
    </>
  );
}

/**
 * モーダル右上に表示される「閉じる」ボタン
 */
function ModalCloseButton() {
  const {
    searchModalDisclosure: { onClose },
  } = useAppContext();
  return (
    <Button
      pos="absolute"
      top={-10}
      right={0}
      variant="ghost"
      color="white"
      _hover={{
        bg: 'blackAlpha.50',
      }}
      onClick={onClose}
      leftIcon={<CloseIcon color="white" />}
    >
      閉じる
    </Button>
  );
}

/**
 * 人気キーワードに関するHooks
 */
function usePopularKeywords() {
  const { apiClient } = useApiClient();

  const [popularKeywords, setPopularKeywords] = useState<string[]>([]);

  const clearPopularKeywords = useCallback(async () => {
    setPopularKeywords([]);
  }, []);

  const fetchPopularKeywords = useCallback(async () => {
    try {
      const res = await apiClient('/api/search-keyword/popular', {
        method: 'GET',
      });
      if (res.ok) {
        const data = await res.json();
        setPopularKeywords(data);
      } else {
        throw new Error();
      }
    } catch (e) {
      monitoring.notify({
        name: `API ERROR DETECTED ${e}`,
        message: '人気キーワードの取得に失敗しました。',
      });
    }
  }, [apiClient]);

  return {
    popularKeywords,
    clearPopularKeywords,
    fetchPopularKeywords,
  };
}

/**
 * 検索履歴に関するHooks
 */
function useSearchHistoryKeywords() {
  const { apiClient } = useApiClient();

  const [searchHistoryKeywords, setSearchHistoryKeywords] = useState<string[]>(
    [],
  );

  const clearSearchHistoryKeywords = useCallback(() => {
    setSearchHistoryKeywords([]);
  }, []);

  const removeSearchHistoryKeywords = useCallback((keyword: string) => {
    setSearchHistoryKeywords((keywords) =>
      keywords.filter((k) => k !== keyword),
    );
  }, []);

  const fetchSearchHistoryKeywords = useCallback(async () => {
    try {
      const res = await apiClient(`/api/search-keyword/history`, {
        method: 'GET',
      });
      if (res.ok) {
        const data = await res.json();
        setSearchHistoryKeywords(data);
      } else {
        throw new Error();
      }
    } catch (e) {
      monitoring.notify({
        name: `API ERROR DETECTED ${e}`,
        message: '検索履歴の取得に失敗しました。',
      });
    }
  }, [apiClient]);

  const deleteSearchHistoryKeywords = useCallback(
    async (keyword: string) => {
      removeSearchHistoryKeywords(keyword);

      try {
        const res = await apiClient(
          `/api/search-keyword/history?keyword=${encodeURIComponent(keyword)}`,
          {
            method: 'DELETE',
          },
        );
        if (!res.ok) {
          throw new Error();
        }
      } catch (e) {
        monitoring.notify({
          name: `API ERROR DETECTED ${e}`,
          message: '検索履歴の削除に失敗しました。',
        });
      }
    },
    [apiClient, removeSearchHistoryKeywords],
  );

  return {
    searchHistoryKeywords,
    clearSearchHistoryKeywords,
    fetchSearchHistoryKeywords,
    deleteSearchHistoryKeywords,
  };
}

/**
 * 検索予測に関するHooks
 */
function usePrefixedWithKeywords() {
  const { apiClient } = useApiClient();

  const [prefixedWithKeywords, setPrefixedWithKeywords] = useState<string[]>(
    [],
  );

  const clearPrefixedWithKeywords = useCallback(() => {
    setPrefixedWithKeywords([]);
  }, []);

  const fetchPrefixedWithKeywords = useCallback(
    async (keyword: string) => {
      try {
        const res = await apiClient(
          `/api/search-keyword/prefixed-with?keyword=${encodeURIComponent(
            keyword,
          )}`,
          {
            method: 'GET',
          },
        );
        if (res.ok) {
          const data = await res.json();
          setPrefixedWithKeywords(data);
        } else {
          throw new Error();
        }
      } catch (e) {
        monitoring.notify({
          name: `API ERROR DETECTED ${e}`,
          message: '検索予測キーワードの取得に失敗しました。',
        });
      }
    },
    [apiClient],
  );

  return {
    prefixedWithKeywords,
    clearPrefixedWithKeywords,
    fetchPrefixedWithKeywords,
  };
}

export default function SearchModal() {
  const modalSize = useBreakpointValue(['xs', undefined, 'md']);
  const { isAuthenticated, searchModalDisclosure } = useAppContext();
  const [inputKeyword, setInputKeyword] = useState('');

  const { popularKeywords, clearPopularKeywords, fetchPopularKeywords } =
    usePopularKeywords();
  const {
    searchHistoryKeywords,
    clearSearchHistoryKeywords,
    fetchSearchHistoryKeywords,
    deleteSearchHistoryKeywords,
  } = useSearchHistoryKeywords();
  const {
    prefixedWithKeywords,
    clearPrefixedWithKeywords,
    fetchPrefixedWithKeywords,
  } = usePrefixedWithKeywords();

  // モーダル開閉時の処理
  useEffect(() => {
    if (searchModalDisclosure.isOpen) {
      fetchPopularKeywords();
      if (isAuthenticated) {
        fetchSearchHistoryKeywords();
      }
    } else {
      setInputKeyword('');
      clearPopularKeywords();
      clearSearchHistoryKeywords();
      clearPrefixedWithKeywords();
    }
  }, [
    searchModalDisclosure.isOpen,
    isAuthenticated,
    fetchSearchHistoryKeywords,
    fetchPopularKeywords,
    clearSearchHistoryKeywords,
    clearPopularKeywords,
    clearPrefixedWithKeywords,
  ]);

  // 入力変化時の処理
  const onChangeInput = async (inputKeyword: string) => {
    setInputKeyword(inputKeyword);

    const trimmedInputKeyword = inputKeyword.trim();
    if (trimmedInputKeyword.length > 0) {
      fetchPrefixedWithKeywords(trimmedInputKeyword);
    }
  };

  // 検索履歴削除時の処理
  const onClickDeleteSearchHistoryKeywords = async (
    searchHistoryKeyword: string,
  ) => {
    sendAnalyticsEvent('slide', 'click', 'search_history_keyword_delete');
    deleteSearchHistoryKeywords(searchHistoryKeyword);
  };

  const suggestType: SuggestType = useMemo(() => {
    if (inputKeyword.trim().length > 0) {
      return 'prefixedWithKeywords';
    } else if (searchHistoryKeywords.length > 0) {
      return 'searchHistoryKeywords';
    } else if (popularKeywords.length > 0) {
      return 'popularKeywords';
    } else {
      return 'none';
    }
  }, [inputKeyword, searchHistoryKeywords, popularKeywords]);

  return (
    <Modal
      size={modalSize}
      onClose={searchModalDisclosure.onClose}
      isOpen={searchModalDisclosure.isOpen}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody p={0} position="relative">
          <Box px={3}>
            <SearchArticlesForm
              onSubmitForm={searchModalDisclosure.onClose}
              onChangeInput={onChangeInput}
            />
          </Box>
          <Divider w="auto" mx={3} />
          <SuggestTitle suggestType={suggestType} ps={4} mt={4} />
          <Box
            minHeight={12}
            maxHeight="200px"
            mt={2}
            overflowY="scroll"
            style={{
              scrollbarWidth: 'none',
            }}
            css={{
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            {suggestType === 'searchHistoryKeywords' && (
              <SuggestKeywords
                suggestKeywords={searchHistoryKeywords}
                gaEventLabel="search_history_keyword_search"
                icon={<CloseIcon color="green" />}
                iconAriaLabel="検索履歴を検索"
                onClickIcon={onClickDeleteSearchHistoryKeywords}
              />
            )}
            {suggestType === 'popularKeywords' && (
              <SuggestKeywords
                suggestKeywords={popularKeywords}
                gaEventLabel="popular_keyword_search"
                icon={<ChevronRightIcon color="green" boxSize={6} />}
                iconAriaLabel="人気キーワードを検索"
              />
            )}
            {suggestType === 'prefixedWithKeywords' && (
              <SuggestKeywords
                suggestKeywords={prefixedWithKeywords}
                gaEventLabel="suggested_keyword_search"
                icon={<ChevronRightIcon color="green" boxSize={6} />}
                iconAriaLabel="検索予測キーワードを検索"
              />
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
