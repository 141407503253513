export enum BadgeSeriesEnum {
  PostSlide = 0, // スライド投稿系
  Comment = 1, // コメント投稿系
}

export enum PostSlideBadgeTypeEnum {
  PostSlide = 0, // スライド投稿
}
export enum CommentBadgeTypeEnum {
  PostComment = 0, // コメント投稿
}

export type Badge = {
  grade: number; // グレード
  createDate: string; // ISO8601
  updateDate: string; // ISO8601
};

export type BadgeArgs = {
  userId: number;
  seriesId: number;
  typeId: number;
  grade: number;
};

export type BadgeConf = {
  grade: number; // グレード
  threshold: number | null; // 閾値
  label: string | null; // ランク固有のバッジラベル（デフォルトを用いる場合はnull）
  announceMessage: string | null; // ランク固有の通知用メッセージ（デフォルを用いる場合はnull）
};

export type BadgeType = 'stepup' | 'individual';

export type BadgeTypeConf = {
  type: BadgeType;
  label: string;
  labelColor: string;
  shortLabel: string;
  imgFilePrefix: string;
  badges: Array<BadgeConf | null>;
};

/**
 * バッジタイプ定義
 *
 * 系毎に複数のバッジタイプを定義可能
 * 定義した文字列はバッジ一覧の見出しとして表示される
 */
export const BADGE_CRITERIA: Array<BadgeTypeConf[]> = [
  // PostSlide
  [
    {
      type: 'stepup',
      label: '投稿',
      labelColor: '#E26044',
      shortLabel: '投稿',
      imgFilePrefix: 'badge_post_slide',
      badges: [
        {
          grade: 0,
          threshold: 1,
          label: 'はじめての投稿',
          announceMessage: 'はじめて投稿しました',
        },
        { grade: 1, threshold: 2, label: null, announceMessage: null },
        { grade: 2, threshold: 5, label: null, announceMessage: null },
        { grade: 3, threshold: 10, label: null, announceMessage: null },
        { grade: 4, threshold: 20, label: null, announceMessage: null },
        { grade: 5, threshold: 30, label: null, announceMessage: null },
        { grade: 6, threshold: 40, label: null, announceMessage: null },
        { grade: 7, threshold: 50, label: null, announceMessage: null },
        { grade: 8, threshold: 60, label: null, announceMessage: null },
        { grade: 9, threshold: 70, label: null, announceMessage: null },
        { grade: 10, threshold: 85, label: null, announceMessage: null },
        { grade: 11, threshold: 100, label: null, announceMessage: null },
      ],
    },
  ],
  // PostComment
  [
    {
      type: 'stepup',
      label: 'コメント',
      labelColor: '#FFCD1A',
      shortLabel: 'コメント',
      imgFilePrefix: 'badge_post_comment',
      badges: [
        {
          grade: 0,
          threshold: 1,
          label: 'はじめてのコメント',
          announceMessage: 'はじめてコメントしました',
        },
        { grade: 1, threshold: 2, label: null, announceMessage: null },
        { grade: 2, threshold: 5, label: null, announceMessage: null },
        { grade: 3, threshold: 10, label: null, announceMessage: null },
        { grade: 4, threshold: 20, label: null, announceMessage: null },
        { grade: 5, threshold: 30, label: null, announceMessage: null },
        { grade: 6, threshold: 40, label: null, announceMessage: null },
        { grade: 7, threshold: 50, label: null, announceMessage: null },
        { grade: 8, threshold: 60, label: null, announceMessage: null },
        { grade: 9, threshold: 70, label: null, announceMessage: null },
        { grade: 10, threshold: 85, label: null, announceMessage: null },
        { grade: 11, threshold: 100, label: null, announceMessage: null },
      ],
    },
  ],
  // ProfileCompletion
  /* 仮実装
    [
      {
        type: "individual",
        label: 'プロフィール充実度',
        labelColor: '#FFCD1A',
        shortLabel: 'プロフィール',
        imgFilePrefix: 'badge_profile_completion',
        badges: [
          { grade: 0, threshold: null, label: 'Twitterユーザー名入力' },
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          { grade: 11, threshold: null, label: '診療科入力' },
        ],
      },
    ],
    */
];
