export type AccountTypeString =
  | 'UNKNOWN'
  | 'SLIDE'
  | 'ANTAA'
  | 'SLIDE_AND_ANTAA';
export type AccountTypeNumber = 0 | 1 | 2 | 3;

const UNKNOWN = 'UNKNOWN';
const SLIDE = 'SLIDE';
const ANTAA = 'ANTAA';
const SLIDE_AND_ANTAA = 'SLIDE_AND_ANTAA';
const UNKNOWN_INT = 0;
const SLIDE_INT = 1;
const ANTAA_INT = 2;
const SLIDE_AND_ANTAA_INT = 3;

export const accountType: {
  UNKNOWN: AccountTypeString;
  SLIDE: AccountTypeString;
  ANTAA: AccountTypeString;
  SLIDE_AND_ANTAA: AccountTypeString;
  UNKNOWN_INT: AccountTypeNumber;
  SLIDE_INT: AccountTypeNumber;
  ANTAA_INT: AccountTypeNumber;
  SLIDE_AND_ANTAA_INT: AccountTypeNumber;
} = {
  UNKNOWN,
  SLIDE,
  ANTAA,
  SLIDE_AND_ANTAA,
  UNKNOWN_INT,
  SLIDE_INT,
  ANTAA_INT,
  SLIDE_AND_ANTAA_INT,
};

type ArgType = AccountTypeString | AccountTypeNumber | string | number;

export function string(accountType: ArgType): AccountTypeString {
  switch (accountType) {
    case UNKNOWN:
    case SLIDE:
    case ANTAA:
    case SLIDE_AND_ANTAA:
      return accountType;
    case UNKNOWN_INT:
      return UNKNOWN;
    case SLIDE_INT:
      return SLIDE;
    case ANTAA_INT:
      return ANTAA;
    case SLIDE_AND_ANTAA_INT:
      return SLIDE_AND_ANTAA;
    default:
      return UNKNOWN;
  }
}

export function number(accountType: ArgType): AccountTypeNumber {
  switch (accountType) {
    case UNKNOWN:
      return UNKNOWN_INT;
    case SLIDE:
      return SLIDE_INT;
    case ANTAA:
      return ANTAA_INT;
    case SLIDE_AND_ANTAA:
      return SLIDE_AND_ANTAA_INT;
    case UNKNOWN_INT:
    case SLIDE_INT:
    case ANTAA_INT:
    case SLIDE_AND_ANTAA_INT:
      return accountType;
    default:
      return UNKNOWN_INT;
  }
}

export function isSlideAccount(accountType: ArgType) {
  return accountType === SLIDE_INT || accountType === SLIDE;
}

export function isAntaaAccount(accountType: ArgType) {
  return accountType === ANTAA_INT || accountType === ANTAA;
}

export function isSlideAndAntaaAccount(accountType: ArgType) {
  return accountType === SLIDE_AND_ANTAA_INT || accountType === SLIDE_AND_ANTAA;
}

export function isContain(targetType: number, checkType: AccountTypeNumber) {
  return (targetType & checkType) !== 0;
}
