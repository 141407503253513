import pino from 'pino';
import { getServerRuntimeConfig } from '../../common/config';

const logger = pino({
  level: getServerRuntimeConfig().logLevel || 'info',
  formatters: {
    level(label, _number) {
      return { level: label };
    },
  },
  redact: {
    paths: ['hostname', 'remotePort', 'req.headers', 'res.headers'],
    remove: true,
  },
});

export default logger;
