import { useRouter } from 'next/router';

export default function useAppRouter() {
  const router = useRouter();
  return {
    ...router,
    pushHome() {
      router.push('/');
    },
    pushLogin(options?: {
      redirectUrl?: string;
      mailAddr?: string;
      accountIntegrationCompleted?: boolean;
    }) {
      const p = new URLSearchParams();
      if (options?.redirectUrl) {
        p.set('redirectUrl', options.redirectUrl);
      }
      if (options?.mailAddr) {
        p.set('mailAddr', options.mailAddr);
      }
      if (options?.accountIntegrationCompleted) {
        p.set(
          'account_integration_completed',
          options.accountIntegrationCompleted ? 'true' : 'false',
        );
      }
      router.push(`/login?${p.toString()}`);
    },
  };
}
