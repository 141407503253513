import React from 'react';
import Bugsnag, { NotifiableError } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import getConfig from 'next/config';

if (process.browser) {
  const { publicRuntimeConfig } = getConfig();
  Bugsnag.start({
    apiKey: publicRuntimeConfig.bugsnagApiKey,
    plugins: [new BugsnagPluginReact(React)],
    releaseStage: publicRuntimeConfig.bugsnagReleaseStage,
    enabledReleaseStages: ['production', 'staging'],
    autoTrackSessions: true,
    appVersion: publicRuntimeConfig.appVersion,
  });
}

function notify(error: NotifiableError) {
  Bugsnag.notify(error);
}

function setUser(id?: string, email?: string, name?: string) {
  Bugsnag.setUser(id, email, name);
}

const monitoring = {
  notify,
  setUser,
};

export default monitoring;
