import { default as getNextConfig } from 'next/config';
import type { Prisma } from '@prisma/client';

export type ServerRuntimeConfig = {
  logLevel: string;
  cookieName: string;
  cookieSecret: string;
  cookieSecure: boolean;
  prismaLogOptions: Array<Prisma.LogLevel | Prisma.LogDefinition>;
  s3Region: string;
  s3Bucket: string;
  sesRegion: string;
  sqsRegion: string;
  sqsQueueNamePpt: string;
  sqsQueueCreateToc: string;
  slackChannel: string;
  slackWebhookUrl: string;
  firebaseAuthIssuerBaseUrl: string;
  firebaseAuthPublicKeyUrl: string;
  firebaseVerifyPasswordUrl: string;
  firebaseWebApiKey: string;
  firebaseProjectName: string;
  likedArticleListLimit: number;
  postedArticleListLimit: number;
  serviceAccountEmail: string;
  serviceAccountPassword: string;
  idBaseUrl: string;
};

export type PublicRuntimeConfig = {
  isProduction: boolean;
  slideWebBaseUrl: string;
  qaApiBaseUrl: string;
  qaWebBaseUrl: string;
  qaManageBaseUrl: string;
  qaResetPasswordUrl: string;
  qaResetEmailUrl: string;
  idBaseUrl: string;
  firebaseCredential: object;
  logRocketId: string;
  gaTrackingId: string;
  gaMeasurementId: string;
  gaContainerId: string;
  gaConversionId: string;
  channelTalkPluginKey: string;
  gaEnableTracking: boolean;
  appVersion: string;
  termsOfServiceUrlPrefix: string;
  termsOfServiceSummaryUrlPrefix: string;
  privacyPolicyUrlPrefix: string;
  privacyPolicySummaryUrlPrefix: string;
  communityGuidelineUrlPrefix: string;
  termsOfAntaaAiAssistantUrlPrefix: string;
};

export type PublicConfig = {
  serverRuntimeConfig: ServerRuntimeConfig;
  publicRuntimeConfig: PublicRuntimeConfig;
};

export default function getConfig(): PublicConfig {
  const { serverRuntimeConfig, publicRuntimeConfig } = getNextConfig();
  return {
    serverRuntimeConfig,
    publicRuntimeConfig,
  };
}

export function getServerRuntimeConfig(): ServerRuntimeConfig {
  const { serverRuntimeConfig } = getConfig();
  return serverRuntimeConfig;
}

export function getPublicRuntimeConfig(): PublicRuntimeConfig {
  const { publicRuntimeConfig } = getConfig();
  return publicRuntimeConfig;
}
