import React, { useEffect } from 'react';
import NextLink from 'next/link';
import {
  Box,
  Button,
  Divider,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { useAppContext } from '../contexts/app';
import useImportantNotice from '../hooks/useImportantNotice';
import { sendAnalyticsEvent } from '../common/libs/googleAnalytics';

export default function ImportantNoticeModal() {
  const { importantNoticeModalDisclosure } = useAppContext();
  const {
    readImportantNotice,
    hasImportantNoticeUnread,
    setHasImportantNoticeUnread,
  } = useImportantNotice();

  // モーダル開閉時の処理
  useEffect(() => {
    if (!hasImportantNoticeUnread) {
      return;
    }
  }, [hasImportantNoticeUnread]);

  const onClose = () => {
    sendAnalyticsEvent('important_notice', 'close', null);
    readImportantNotice();
    setHasImportantNoticeUnread(false);
    importantNoticeModalDisclosure.onClose();
  };

  const onClickUpload = () => {
    sendAnalyticsEvent('important_notice', 'click', 'upload');
    onClose();
  };

  return (
    <Modal
      size={'lg'}
      onClose={onClose}
      isOpen={importantNoticeModalDisclosure.isOpen}
    >
      <Box pos="relative">
        <ModalOverlay />
        <ModalContent pos={'absolute'} right={[null, null, 10]}>
          <ModalHeader fontSize={'2xl'} color={'green'}>
            スライドを投稿して誰かの力に。
          </ModalHeader>
          <ModalCloseButton />
          <Divider borderColor="gray.900" />
          <ModalBody m={4} mb={4} ml={2} mr={2}>
            <Text fontSize={'md'}>
              下記テーマのスライドがリクエストされています。
            </Text>
            <UnorderedList mt={8} mb={8} pl={4} fontSize={'lg'}>
              <ListItem>多系統萎縮症</ListItem>
              <ListItem>神経伝導速度</ListItem>
              <ListItem>先天性心疾患</ListItem>
              <ListItem>消化管穿孔</ListItem>
              <ListItem>副甲状腺機能低下症</ListItem>
              <ListItem>base excess</ListItem>
            </UnorderedList>
            <Text fontSize={'md'}>あなたの投稿をお待ちしております！</Text>
          </ModalBody>
          <ModalFooter>
            <NextLink href="/article/upload" passHref>
              <Button as="a" h={9} colorScheme="brand" onClick={onClickUpload}>
                スライドを投稿する
              </Button>
            </NextLink>
          </ModalFooter>
        </ModalContent>
      </Box>
    </Modal>
  );
}
