import React, {
  ChangeEventHandler,
  FormEventHandler,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import {
  FormControl,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import { SearchIcon, CloseIcon } from '@chakra-ui/icons';
import { sendAnalyticsEvent } from '../common/libs/googleAnalytics';

function SearchArticlesForm({
  keyword = '',
  onSubmitForm,
  onChangeInput,
}: {
  keyword?: string;
  onSubmitForm: () => void;
  onChangeInput?: (searchedKeyword: string) => void;
}) {
  const router = useRouter();
  const [query, setQuery] = useState(keyword);
  const inputRef = useRef<HTMLInputElement>(null);

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setQuery(e.target.value);

      onChangeInput?.(e.target.value);
    },
    [onChangeInput],
  );
  const onSubmit = useCallback<FormEventHandler>(
    (e) => {
      e.preventDefault();
      sendAnalyticsEvent('slide', 'index', 'keyword');
      onSubmitForm();
      if (query.length > 0) {
        router.push(`/search?q=${encodeURIComponent(query)}`);
        inputRef.current?.blur();
      }
    },
    [router, query, onSubmitForm],
  );
  const onClearInput = () => {
    inputRef.current?.focus();
    setQuery('');
    onChangeInput?.('');
  };

  return (
    <FormControl as="form" action="" onSubmit={onSubmit}>
      <InputGroup>
        <InputLeftElement>
          <SearchIcon mr={2} color="blackAlpha.900" />
        </InputLeftElement>
        <Input
          autoFocus
          borderRadius="full"
          ref={inputRef}
          type="search"
          onChange={onChange}
          value={query}
          variant="unstyled"
          py={2}
          css={{
            '&::-webkit-search-cancel-button': {
              display: 'none',
            },
          }}
        />
        {query.length > 0 && (
          <InputRightElement>
            <IconButton
              aria-label="検索入力域をクリアする"
              icon={<CloseIcon color="green" />}
              bg="none"
              onClick={onClearInput}
            />
          </InputRightElement>
        )}
      </InputGroup>
    </FormControl>
  );
}

export default SearchArticlesForm;
