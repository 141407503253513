import { randomBytes } from 'crypto';
import connect, { NextHandler } from 'next-connect';
import {
  ApiRequest,
  ApiResponse,
  PageRequest,
  PageResponse,
} from '../libs/connect';
import getConfig from '../../common/config';
import { CsrfVerifyError } from '../libs/error';
import logger from '../libs/logger';
import { setErrorResponse } from './apiWrapper';

/*
 * CSRF対策ミドルウェア
 *   Double Submit Cookie techniqueによる実装
 * @see https://cheatsheetseries.owasp.org/cheatsheets/Cross-Site_Request_Forgery_Prevention_Cheat_Sheet.html#double-submit-cookie
 */

const { serverRuntimeConfig } = getConfig();
const csrfCookieName = () => {
  // @see https://cheatsheetseries.owasp.org/cheatsheets/Cross-Site_Request_Forgery_Prevention_Cheat_Sheet.html#cookie-with-__host-prefix
  return `${serverRuntimeConfig.cookieSecure ? '__Host-' : ''}_csrf-token`;
};

type Request = PageRequest | ApiRequest;
type Response<T = any> = PageResponse | ApiResponse<T>;
export type CsrfToken = string | null;

export const CSRF_TOKEN_VERIFY_TARGET_METHODS = [
  'POST',
  'PATCH',
  'PUT',
  'DELETE',
];
export const CSRF_TOKEN_HEADER_NAME = 'X-CSRF-TOKEN';

interface CsrfCookie {
  name: string;
  options: {
    httpOnly: boolean;
    sameSite: string;
    path: string;
    secure: boolean;
  };
  value: string;
}
interface CreateCsrfTokenParams {
  doVerifyToken: boolean;
  reqCookieToken?: string;
  reqHeaderToken?: string;
}

/**
 * CSRFトークンの検証および生成
 *
 * CSRFトークンの生存期間は `once per user session` とする。
 * @see https://cheatsheetseries.owasp.org/cheatsheets/Cross-Site_Request_Forgery_Prevention_Cheat_Sheet.html#synchronizer-token-pattern
 */
export function verifyAndCreateCsrfToken({
  doVerifyToken,
  reqCookieToken,
  reqHeaderToken,
}: CreateCsrfTokenParams): {
  csrfToken: string;
  csrfTokenCookie: CsrfCookie | null;
  verifyError: boolean | null;
} {
  logger.debug(
    `<csrf> verifyAndCreateCsrfToken START; doVerifyToken = ${doVerifyToken}; reqCookieToken = ${reqCookieToken}; reqHeaderToken = ${reqHeaderToken}`,
  );

  let csrfToken;
  let csrfTokenCookie = null;
  let verifyError = null;
  if (reqCookieToken) {
    if (doVerifyToken) {
      if (reqCookieToken === reqHeaderToken) {
        logger.debug(`<csrf> verifyAndCreateCsrfToken END; verify sucseeded`);
        verifyError = false;
      } else {
        logger.debug(`<csrf> verifyAndCreateCsrfToken; verify error`);
        verifyError = true;
      }
    }
    csrfToken = reqCookieToken;
  } else {
    csrfToken = randomBytes(32).toString('hex');
    csrfTokenCookie = {
      name: csrfCookieName(),
      options: {
        httpOnly: true,
        sameSite: 'lax',
        path: '/',
        secure: serverRuntimeConfig.cookieSecure,
      },
      value: csrfToken,
    };
    logger.debug(`<csrf> verifyAndCreateCsrfToken; token created`);
  }

  logger.debug(
    `<csrf> verifyAndCreateCsrfToken END; csrfToken = ${csrfToken}; csrfTokenCookie = ${JSON.stringify(
      csrfTokenCookie,
    )}; verifyError = ${verifyError}`,
  );
  return { csrfToken, csrfTokenCookie, verifyError };
}

const getCsrfTokenFromCookie = (req: Request) => {
  return req.cookies[csrfCookieName()];
};

const getCsrfTokenFromHeader = (req: Request) => {
  const headers = req.headers[CSRF_TOKEN_HEADER_NAME.toLowerCase()];
  return Array.isArray(headers) ? headers[0] : headers;
};

const setCsrfCookie = (res: Response, csrfTokenCookie: CsrfCookie) => {
  logger.debug(
    `<csrf> setCsrfCookie; csrfTokenCookie = ${JSON.stringify(
      csrfTokenCookie,
    )}`,
  );
  let cookieVal = `${csrfTokenCookie.name}=${csrfTokenCookie.value}; path=${
    csrfTokenCookie.options.path
  }; samesite=${csrfTokenCookie.options.sameSite}; httpOnly=${
    csrfTokenCookie.options.httpOnly ? 'true' : 'false'
  }`;
  if (csrfTokenCookie.options.secure) {
    cookieVal += '; Secure';
  }
  res.setHeader('set-cookie', cookieVal);
};

const csrf = connect().use(
  async (req: Request, res: Response, next: NextHandler) => {
    logger.debug('>> csrf - START');

    try {
      const { csrfToken, csrfTokenCookie, verifyError } =
        verifyAndCreateCsrfToken({
          doVerifyToken: CSRF_TOKEN_VERIFY_TARGET_METHODS.includes(
            req.method ?? '',
          ),
          reqHeaderToken: getCsrfTokenFromHeader(req),
          reqCookieToken: getCsrfTokenFromCookie(req),
        });
      logger.debug(
        `== csrf; csrfToken = ${csrfToken}; csrfTokenCookie = ${JSON.stringify(
          csrfTokenCookie,
        )}`,
      );

      res.csrfToken = csrfToken;
      if (csrfTokenCookie) {
        setCsrfCookie(res, csrfTokenCookie);
      }
      if (verifyError) {
        throw new CsrfVerifyError('request error');
      }
    } catch (err) {
      logger.error(`== csrf; err = ${err}`);
      return 'status' in res
        ? setErrorResponse(req as ApiRequest, res, err as Error)
        : 'err' in res
        ? (res.err = err as Error)
        : next(err);
    }

    logger.debug('<< csrf - END');
    return next();
  },
);

export default csrf;
